.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
 
  margin: 40px;
}

.title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
 
}

.columns {
  display: flex;
  justify-content: space-between;
}

.left-column, .right-column {
  width: 45%;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 768px) {
  .columns {
    flex-direction: column;
  }

  .left-column, .right-column {
    width: 100%;
    margin-bottom: 20px;
  }
}