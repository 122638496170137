.module-links-container {
    text-align: left;
  }
  
  .module {
    margin-bottom: 20px;
  }
  
  .child-module {
    margin-left: 20px;
  }
  
  .problem-link {
    margin-left: 40px;
  }

  .sub-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 20px;
   
  }
  resource-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
  }