.resource-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  

  .edu-links-container {
    text-align: left;
  }
  